<template lang="html">
  <div id="app" class="container-fluid">
    <b-navbar class="row main-header shadow navbar-expand-md" toggleable="sm" sticky>
      <div class="col-12">
        <MainMenu />
      </div>

      <b-navbar-brand :to="{ name: 'home' }" class="navbar-brand-centered">
        <b-img src="./assets/logo-seija@2x.png" width="49px" alt="Seija" />
      </b-navbar-brand>
    </b-navbar>

    <vue-page-transition name="overlay-up-full">
      <router-view :key="$route.fullPath" />
    </vue-page-transition>

    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";

export default {
  components: {
    MainMenu,
  },

  methods: {
    afterEnter: () => {
      window.scrollTo(0, 0);
    },
  },

  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
  },
  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();

      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish();
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

// overlays
.overlay-top,
.overlay-right,
.overlay-left,
.overlay-bottom {
  background-color: #eee !important;
  z-index: 1000;
}

// Animations

/// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/// Slide

.slide-left-leave-active,
.slide-left-enter-active,
.slide-right-leave-active,
.slide-right-enter-active {
  transition: 0.5s;
}

.slide-left-enter {
  transform: translate(100%, 0);
}

.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-right-enter {
  transform: translate(-100%, 0);
}

.slide-right-leave-to {
  transform: translate(100%, 0);
}

// Global defs

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
html.menu-open {
  overflow: hidden;
}

a {
  color: #35434a !important;

  &.router-link-active {
    color: $orange !important;
  }
}

[id]::before {
  content: "";
  display: block;
  height: 75px;
  margin-top: -75px;
  visibility: hidden;
}

* {
  font-family: Montserrat !important;
}

// Navbar
.navbar-brand-centered {
  position: absolute !important;
  left: 50% !important;
  display: block;
  width: 160px;
  text-align: center;
  background-color: transparent;
}

.navbar > .navbar-brand-centered {
  margin-left: -80px;
}

.navbar {
  padding: 0;
  background-color: white;
  opacity: 0.95;
  height: 70px;
}

.container-fluid {
  padding: 0 !important;
}

h1,
h2,
h3 {
  color: $orange;
}
</style>
