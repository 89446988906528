<template lang="html">
    <ul v-if="collections && collections.length > 0" class="collection-list"> <!-- collections -->
        <li v-for="collection in collections" v-bind:key="collection.name">
            <router-link :to="{'name': 'collection', params: {slug: collection.slug}}">
                <b-row align-v="center" class="item">
                    <b-col cols="12" lg="3" offset-lg="1" order-lg="1" order="1" class="name text-center">
                        {{ collection.name }}
                    </b-col>
                    <b-col cols="12" lg="4" order-lg="2" order="4" class="image">
                        <b-img v-if="collection.thumbnail.formats.small" center :src="collection.thumbnail.formats.small.url|api_static_url" fluid/>
                        <b-img v-else center :src="collection.thumbnail.formats.thumbnail.url|api_static_url" fluid/>

                        <span style="background-color: red;" class="text-white" v-if="!collection.thumbnail.formats.small">Image trop petite : {{ collection.thumbnail.width }}*{{ collection.thumbnail.height }} ; taille attendue: 501px*220px</span>
                    </b-col>
                    <b-col cols="6" lg="1" order-lg="3" order="2" class="category text-center">
                        {{ collection.function }}
                    </b-col>
                    <b-col cols="6" lg="1" order-lg="4" order="3" class="type text-center">
                        {{ collection.matter }}
                    </b-col>

                </b-row>
            </router-link>
        </li>
    </ul>
    <ul class="collection-list" v-else>
        <li>
            <b-col cols="12" align-v="center" class="name">
                Pas de collection à afficher !
            </b-col>
        </li>
    </ul>
</template>

<script lang="js">

import Vue from 'vue';

export default {
    name: 'CollectionList',

    data: function() {
        return {
            collections: null
        }

    },

    props: {
        type: String
    },

    mounted: function () {
        let self = this
        Vue.axios.get("/collections?_sort=home_position&collection_type=" + this.$props.type)
           .then(function(response) {
               self.$data.collections  = response.data
           })
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "@/assets/scss/_variables.scss";

.collection-list {
    padding: 0;
    margin-bottom: 0;
    font-weight: 300;
    color: #7a8f99;

    background-color: #f5f5f5;


    .category {
        font-style: italic;
    }

    li {
        list-style: none;
        margin-left: none;

        img {
            max-width: 100%;
        }

    }

    .item {
        color: $grey_blue;

        &:nth-child(odd) {
            border-bottom: 1px solid #ddd;
        }

        .name {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            color: #7a8f99;
        }
    }
}

</style>
