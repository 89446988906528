<template lang="html">
    <ul class="link-list">
        <li v-for="link in links" :key="link.id">
            <router-link v-if="link.page" :to="{name: 'textpage', params: {page_name: link.page.slug}}">{{ link.label || link.page.title }}</router-link>
            <a v-else target="_new" :href="link.url">{{ link.label || link.url }}</a>
        </li>
    </ul>
</template>

<script lang="js">


export default {
    name: 'LinkList',

    data: function() {
        return {
        }

    },

    props: {
        links: Array
    },

    mounted: function () {
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "@/assets/scss/_variables.scss";

.link-list {
    list-style: none;
    padding: 0px;
    margin: 0px;

    a {
        font-size: 1.2rem;
        font-weight: 800 !important;
        color: $orange !important;
    }
}

</style>
