<template lang="html">
    <b-carousel
    :interval="4000"
        fade
        controls
        indicators
        class="w-100"
        style="text-shadow: 1px 1px 2px #333;"
        img-height="200px"
    >
        <!-- Text slides with image -->
        <b-carousel-slide v-for="image in images" :key="image.id">
            <template #img>
                <b-img class="d-block img-fluid w-100"
                       :srcset="$options.filters.api_static_url(image.url) + ' 1800w, ' + $options.filters.api_static_url(image.formats.large.url) + ' 700w, ' + $options.filters.api_static_url(image.formats.medium.url) + ' 500w'"
                       sizes="(max-width: 1800px), 1024w, 700w, 500w"
                       :src="image.formats.small.url|api_static_url"
                />
            </template>
        </b-carousel-slide>

    </b-carousel>
</template>

<script lang="js">

var data = {
}

export default {
    // eslint-disable-next-line
    name: 'Carousel',

    data: function() {
        return data
    },

    props: {
        images: Array
    },

    methods: {
    },

    mounted: function () {
    }

}
</script>

<style lang="scss" scoped>
</style>
