<template lang="html">
    <div class="product-showcase">
        <b-row v-for="(showcase, index) in showcases" class="collection-showcase-row mt-5 mb-5" align-v="center" align-h="center" :key="showcase.id">
            <b-modal :id="'modal-'+index" size="xl" dialog-class="showcase-modal-dialog" body-class="showcase-modal-body" hide-footer hide-header lazy centered>
                <div>
                    <b-carousel
                        id="carousel-fade"
                        indicators
                        controls
                    >
                        <div v-for="picture in showcase.pictures" :key="picture.id">
                            <b-carousel-slide>
                                <template #img>
                                    <b-img-lazy v-if="picture.formats.large"
                                                class="d-block img-fluid w-100"
                                                :src="picture.formats.large.url|api_static_url"
                                    />

                                    <b-img-lazy v-else
                                                class="d-block img-fluid w-100"
                                                :src="picture.url|api_static_url"
                                    />
                                </template>
                            </b-carousel-slide>
                        </div>
                    </b-carousel>
                </div>
            </b-modal>

            <b-col cols="10" lg="2" class="text-center">
                <b-img v-if="showcase.shot" :src=showcase.shot.formats.small.url|api_static_url fluid/>
            </b-col>

            <b-col offset-lg="1" cols="10" lg="3" class="text-center text-lg-left">
                <h4 class="product-showcase-title">{{ showcase.title }}</h4>
                <p class="product-description mt-2 mb-4">
                    <vue-markdown :source="showcase.description"></vue-markdown>
                </p>
                <p>
                    <a href="javascript:;" @click="$bvModal.show('modal-' + index)">Voir en détails</a>
                </p>
            </b-col>

            <b-col cols="8" lg="2" class="text-center">
                <b-img v-if="showcase.sketch" :src=showcase.sketch.url|api_static_url fluid/>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="js">

import VueMarkdown from '@adapttive/vue-markdown'

var data = {
    show: false
}


export default {
    name: 'ShowcaseList',

    components: {
        VueMarkdown
    },

    data: function() {
        return data
    },

    props: {
        showcases: Array
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "@/assets/scss/_variables.scss";

.product-showcase-title {
    font-size: 2em;
}

.product-description {
    color: $light_grey;
}

.modal-backdrop {
    background-color: white !important;
}
.showcase-modal-body {
    background-color: none;
}

.showcase-modal-dialog {
    .modal-content {
        border: none !important;
    }
}


a {
    text-decoration: none;
    color: $orange !important;
    font-weight: 800 !important;
}

</style>
