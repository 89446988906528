<template lang="html">
  <b-container fluid no-gutters class="generic">
    <div v-if="!page.is_loading && page.heading_background" class="row p-0 text-center bg-image" style="height: 400px" :style="`background-image: url(` + $options.filters.api_static_url(page.heading_background.url) + `);`">
      <div class="mask h-100 w-100" style="background-color: rgba(0, 0, 0, 0.6);`">
        <div class="d-flex justify-content-center align-items-center h-100 w-100">
          <div class="text-white text-uppercase">
            <h1 class="text-page-title">{{ page.title }}</h1>
          </div>
        </div>
      </div>
    </div>

    <b-row align-h="center" class="mt-n5">
      <b-col cols="12" md="10" lg="8" class="p-5" style="background-color: white">
        <b-overlay :show="page.is_loading">
          <b-row class="mb-5" align-h="center" v-if="page.opening">
            <b-col cols="12" class="text-left align-middle">
              <vue-markdown :source="page.opening"></vue-markdown>
            </b-col>
          </b-row>

          <div v-for="(element, index) in page.elements" :key="index">
            <!-- Carousel -->
            <div v-if="element.__component == 'generic.carousel'">
              <Carousel style="height: auto" class="mt-5 mb-5" v-if="element.images.length > 1" :images="element.images" />
              <b-row v-else>
                <b-col cols="12">
                  <b-img class="w-100 mt-5 mb-5" :src="element.images[0].url | api_static_url" />
                </b-col>
              </b-row>
            </div>

            <!-- Text block -->
            <b-row class="mt-5 mb-5" align-h="center" v-if="element.__component == 'generic.text-with-title'">
              <b-col cols="12" class="text-left align-middle">
                <p>
                  <vue-markdown :source="element.content"></vue-markdown>
                </p>
                <LinkList :links="element.links" />
              </b-col>
            </b-row>

            <!-- Text with picture -->
            <b-row class="mt-5 mb-5" align-h="left" v-if="element.__component == 'generic.text-with-picture'">
              <b-col class="p-3" cols="12" lg="5">
                <b-img :src="element.image.formats.medium.url | api_static_url" class="w-100" />
              </b-col>
              <b-col cols="12" lg="6" offset="0" offset-lg="1" class="p-3 text-left align-middle">
                <p>
                  <vue-markdown :source="element.text"></vue-markdown>
                </p>
                <LinkList :links="element.links" />
              </b-col>
            </b-row>

            <!-- 2 columns text -->
            <b-row class="mt-5 mb-5" align-h="center" v-if="element.__component == 'generic.text-2-columns'">
              <b-col cols="12" lg="6" class="p-3 text-left">
                <p>
                  <vue-markdown :source="element.text_left"></vue-markdown>
                </p>
                <LinkList :links="element.links_left" />
              </b-col>
              <b-col cols="12" lg="6" class="p-3 text-left">
                <p>
                  <vue-markdown :source="element.text_right"></vue-markdown>
                </p>
                <LinkList :links="element.links_right" />
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-col>
    </b-row>

    <Footer />
  </b-container>
</template>

<script lang="js">
import Vue from 'vue'

import VueMarkdown from '@adapttive/vue-markdown'

// @ is an alias to /src
import Carousel from '@/components/Carousel.vue'
import LinkList from '@/components/LinkList.vue'

import Footer from '@/components/Footer.vue'

var data = {
    page: {is_loading: true}
}



export default {
    name: 'TextPage',
    components: {
        VueMarkdown,
        Carousel,
        LinkList,
        // eslint-disable-next-line
        Footer,
    },

    methods: {
    },

    data: function() {
        return data
    },


    mounted: function () {
        Vue.axios.get("/pages/" + this.$route.params.page_name)
           .then(function(response) {
               data.page  = response.data
           })
           .catch(() =>
               this.$router.replace("/")
           )
    }
}
</script>

<style scoped lang="scss">
.text-page-title {
  color: white;
}

h2 {
  margin-bottom: 30px;
}

h3 {
  margin-bottom: 20px;
}
</style>
