<template lang="html">

    <b-row align-h="center" v-if="images.length" no-gutters>
        <!-- Single picture -->
        <b-col v-if="images.length == 1" cols="10">
            <b-img-lazy width="100%" :src=images[0].image.formats.medium.url|api_static_url
                   class="w-100"
                   alt=""
            />
        </b-col>
        <!-- More than a picture -->
        <b-col v-else lg="6" md="10" cols="12" class="pr-lg-2">
            <!-- Image #1 -->
            <b-img-lazy :src=images[0].image.formats.medium.url|api_static_url
                   class="w-100 pb-2"
                        alt=""
            />

            <b-row align-h="center" no-gutters class="pt-2 pb-2">
                <!-- Exactly 3 pictures -->
                <b-col cols="12" v-if="images.length == 3">

                    <b-img-lazy :src=images[2].image.formats.medium.url|api_static_url
                                class="w-100"
                                alt="XX"
                    />

                </b-col>

                <!-- More than 3 pictures -->

                <!-- Image #3 -->
                <b-col cols="6" v-else-if="images.length > 2" class="pr-2">
                    <b-img-lazy :src=images[2].image.formats.medium.url|api_static_url
                                class="w-100"
                    />

                </b-col>

                <!-- Image #4 -->
                <b-col cols="6" class="pl-2" v-if="images.length > 3">
                    <b-img-lazy :src=images[3].image.formats.small.url|api_static_url
                           class="w-100"
                           alt=""
                    />


                </b-col>
            </b-row>
        </b-col>

        <b-col lg="4" md="10" cols="12" class="pl-lg-2">
            <!-- Image #2 -->
            <b-row align-h="center" v-if="images.length > 1" class="pb-2 pt-2 pt-lg-0">
                <b-col>
                    <b-img-lazy :src=images[1].image.formats.medium.url|api_static_url
                                class="w-100"
                                alt=""
                    />
                </b-col>
            </b-row>
            <!-- Image #5 -->
            <b-row align-h="center" v-if="images.length > 4" class="pt-2">
                <b-col>
                    <b-img-lazy :src=images[4].image.formats.medium.url|api_static_url
                           class="w-100"
                           alt=""
                    />
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</template>

<script lang="js">

var data = {
}


export default {
    name: 'ImageGrid',

    data: function() {
        return data
    },

    props: {
        images: Array
    },

    methods: {
    },

    mounted: function () {
    }

}
</script>

<style lang="scss" scoped>
</style>
