<template lang="html">
    <b-overlay :show="collection == null" style="height: 100vh">
        <b-container fluid no-gutters v-if="collection">
            <b-row class="header-back-row">
                <b-col class="text-center">
                    <router-link :to="{name: 'collection-list', params: {type: collection.collection_type}}">&larr; Retour aux collections</router-link>
                </b-col>
            </b-row>

            <b-row class="collection-name-row">
                <b-col class="text-center">
                    <h2 class="collection-title">{{ collection.name }}</h2>
                </b-col>
            </b-row>

            <b-row class="collection-type-row">
                <b-col class="text-center">
                    {{ collection.matter }}
                </b-col>
            </b-row>

            <b-row >
                <Carousel :images="collection.carousel_pictures"/>
            </b-row>


            <b-row class="collection-description-row mb-5" align-h="center">
                <b-col cols="11" sm="8" md="8" class="text-center align-middle">
                    <vue-markdown :source="collection.description"></vue-markdown>
                </b-col>
            </b-row>

            <ShowcaseList v-if="collection.collection_type == 'own'" :showcases="collection.showcases" class="mb-5" />

            <ImageGrid :images="collection.pictures" />

            <div v-if="collection.collection_type == 'custom' && collection.showcases.length">
                <b-row class="mt-5">
                    <b-col offset="1" cols="10">
                        <h3 style="margin-left: -7px;" class="display-4 mb-4">Pièces Signature</h3>
                        <p>
                            Nous avons choisi avec l’équipe du restaurant quelques pièces de notre collection pour vous les proposer afin de retrouver l’ambiance du restaurant chez vous.
                        </p>
                    </b-col>
                </b-row>
                <ShowcaseList  :showcases="collection.showcases" class="mb-5" />

                <CustomCraftInsert />
            </div>
            <Footer class="mt-5" />

        </b-container>
    </b-overlay>
</template>


<script lang="js">
import Vue from 'vue';

import VueMarkdown from '@adapttive/vue-markdown'

import Footer from '@/components/Footer.vue'
import ShowcaseList from '@/components/ShowcaseList.vue'
import ImageGrid from '@/components/ImageGrid.vue'
import CustomCraftInsert from '@/components/CustomCraftInsert.vue'
import Carousel from '@/components/Carousel.vue'

var data = {
    collection: null
}


export default {
    // eslint-disable-next-line
    name: 'Collection',

    components: {
        VueMarkdown,
        CustomCraftInsert,
        // eslint-disable-next-line
        Footer,
        ShowcaseList,
        ImageGrid,
        Carousel
    },

    data: function() {
        return data
    },

    mounted: function () {
        data.collection = null;
        
        Vue.axios.get("/collections/?slug=" + this.$route.params.slug)
           .then(function(response) {
               data.collection  = response.data[0]
           })

    },

}

</script>


<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

$grey_bg: #f5f5f5;

#collection-carousel {
    .carousel-inner {
        .carousel-item {
            // height: 600px !important;
        }
    }
}

.header-back-row {
    background-color: $grey_bg;
    text-transform: capitalize;

    font-style: italic;

    a {
        color: #888 !important;   
    }

    padding-bottom: 3vh;
    padding-top: 3vh;

}


.collection-name-row {
    background-color: $grey_bg;

    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-bottom: 4vh;
    padding-top: 4vh;
}

.collection-description-row {
    background-color: white;

    border-top: 1px solid #ddd;
    padding-top: 10vh;

    line-height: 2em;
}


.collection-type-row {
    background-color: $grey_bg;

    color: $light_grey;
    padding-bottom: 3vh;
    padding-top: 3vh;

    text-transform: capitalize;

}

h4.product-showcase-title {
    font-size: 1.5em;
    color: #35434a;
}


h2.collection-title {

    color: $orange;
    font-size: 6vw;
    text-transform: uppercase;
    font-weight: 700;

    @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
        font-weight: 500 !important;
    }

    font-family: Montserrat;
    margin-bottom: 0;
}
</style>
