import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Collection from './views/Collection'
import CollectionList from './views/CollectionList'
import TextPage from './views/TextPage'
import VueScrollTo from 'vue-scrollto';

Vue.use(Router)

export default new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            VueScrollTo.scrollTo(to.hash, 700);

            //This does
            return {el: to.hash};
        }
        else if (savedPosition) {
            return savedPosition
        }
        return {x: 0, y:0}
    },
  base: process.env.BASE_URL,
  routes: [
      {
          path: '/',
          name: 'home',
          component: Home,
          meta: { transition: 'overlay-left' },
      },
      {
          path: '/collection/:slug',
          name: 'collection',
          component: Collection
      },
      {
          path: '/collections/:type',
          name: 'collection-list',
          component: CollectionList
      },
      {
          name: 'textpage',
          path: '/p-:page_name(.*)',// will match anything starting with `/p-` and put it under `$route.params.afterUser`
          component: TextPage
      },

    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }

  ],
})
