<template lang="html">
    <b-container fluid no-gutters class="generic">

        <b-row class="header" align-v="start" >

            <b-col offset-lg="2" lg="2" offset="1" cols="10" class="text-center text-lg-left">
                <h2 v-if="$route.params.type == 'own'" class="main">Mes collections personnelles</h2>
                <h2 v-if="$route.params.type == 'custom'" class="main">Nos créations sur-mesure</h2>
            </b-col>

            <b-col class="description mt-2 text-center text-lg-left" offset="1" offset-lg="1" cols="10" md="10" lg="5">
                <p v-html="headings[$route.params.type]">
                </p>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col cols="12">
                <CollectionList :type="$route.params.type" />
            </b-col>
        </b-row>

        <Footer/>

    </b-container>

</template>

<script lang="js">

// @ is an alias to /src
import CollectionList from '@/components/CollectionList.vue'

import Footer from '@/components/Footer.vue'


export default {
    name: 'CollectionListPage',
    data: function data() {
        return {
            headings: {'custom': `Issues d’une rencontre, les créations sur mesure sont conçues et réalisées exclusivement pour les lieux pour lesquelles elles ont été pensées.`,
                       'own': `Issue de mon imaginaire, chaque série est la retranscription d’un de mes moments de vie. D’une sensation. D’un sentiment. D’un moment à partager.`}
        }
    },

    components: {
        CollectionList,
        // eslint-disable-next-line
        Footer,
    }
}

</script>

<style scoped lang="scss">

@import "@/assets/scss/_variables.scss";

.header {
    height: 400px;
    padding-top: 150px;
}

.description {
    color: $light_grey;
    line-height: 1.8em;
}

</style>
