<template lang="html">
    <div>
        <div id="section-contact" class="contact">
            <b-row align-h="center">
                <b-col sm="10" md="10" lg="4" class="text-center contact-me mb-1 mt-1">
                    Pour toute question ou demande, contactez-nous !
                </b-col>
                <b-col lg="2" class="text-center email mt-1 mb-1">
                    mail. <a href="mailto:info@seijaceramics.com">info@seijaceramics.com</a>
                </b-col>
                <b-col lg="2" class="text-center telephone mt-1 mb-1">
                    tél. <a href="tel:0374098855">03 74 09 88 55</a>
                </b-col>

            </b-row>
        </div>


        <div class="footer">

            <b-row align-h="center">
                <b-col cols="12" lg="2" class="text-center mb-5">
                    <b-img src="./../assets/logo-seija@2x.png" alt="logo" width="77px"/>
                </b-col>

                <b-col offset="1" cols="5" offset-md="1" md="4" lg="2" class="mb-5">
                    <h4 class="mb-3">L'atelier</h4>

                    3 av. Verhaeren D37<br>
                    59000 Lille<br><br>
                    Acces atelier :  <a href="https://www.google.fr/maps/place/Seija/@50.6207833,3.0298253,199m/data=!3m1!1e3!4m6!3m5!1s0x47c2d513b4124dd5:0x759a2b5e7829c242!8m2!3d50.6207881!4d3.0298849!16s%2Fg%2F11gy0ssg4l?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D" target="_new"><em>7 rue henri Barbusse 59000 LILLE</em></a>

                </b-col>

                <b-col cols="6" md="4" lg="2" class="mb-5" >
                    <ul class="list-unstyled">
                        <li class="mb-2"><router-link :to="{name: 'collection-list', params: {type: 'own'}}">Collections personnelles</router-link></li>
                        <li class="mb-2"><router-link :to="{name: 'collection-list', params: {type: 'custom'}}">Créations sur-mesure</router-link></li>
                        <li class="mb-2"><router-link to="/p-l-atelier">L'atelier</router-link></li>
                        <li class="mb-2"><router-link to="/p-les-formations">Formations</router-link></li>
                    </ul>

                </b-col>

                <b-col offset="1" cols="5" offset-md="1" offset-lg="0" md="4" lg="2" class="mb-5">
                    <ul class="list-unstyled shop-links">
                        <li class="mb-3"><a href="https://seija-ceramics.myshopify.com/" target="_new">Boutique Seija</a></li>
                    </ul>
                </b-col>

                <b-col cols="6" md-offset="1" lg-offset="1" md="4" lg="2" class="mb-5">
                    <h4>Me Suivre</h4>

                    <ul class="list-unstyled">
                        <li><a class="font-weight-normal" href="https://www.instagram.com/seija.ceramics/" target="_">Instagram</a></li>
                        <li><a class="font-weight-normal" href="https://www.facebook.com/seijaceramics/" target="_">Facebook</a></li>
                    </ul>
                </b-col>

            </b-row>

        </div>
    </div>
</template>

<script lang="js">

export default {
    // eslint-disable-next-line
    name: 'Footer', 

    data: function() {
        return {
            collections: null
        }

    },

    props: {
    },

    filters: {
    },

    mounted: function () {
    }

}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.footer {
    margin-bottom: 40px;
    padding-top: 50px;
    border-top: 1px solid #f5f5f5;
    color: $light_grey;

    line-height: 1.5em;

    h4 {
        font-size: 16px;
        font-weight: 600;
        color: $dark_grey;
    }

    ul li {
        line-height: 1.7em !important;
    }

    a {
        color: $dark_grey !important;
        font-weight: bold;
    }
}


#section-contact {
    a {
        color: $orange !important;
    }
}
</style>
