import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueAxios from 'vue-axios'
import axios from 'axios'

Vue.config.productionTip = false

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)

import VueProgressBar from 'vue-progressbar'


const options = {
    color: '#f6a48d',
    failedColor: '#874b4b',
    thickness: '1px',
    transition: {
        speed: '3s',
        opacity: '1.0s',
        termination: 1000
    },
    autoRevert: true,
    location: 'top',
    inverse: false
}

Vue.use(VueProgressBar, options)

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.filter('api_static_url', function (path) {
    return process.env.VUE_APP_API_URL + path
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
