<template lang="html">
  <div>
    <div class="menu-button">
      <div id="main-menu-icon" :class="{ open: menu_visible }" @click="handleToggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <transition name="slide-left">
      <div class="menu" v-if="menu_visible" :class="{ open: menu_visible }">
        <div align-h="center" class="h-100" align-v="end">
          <div class="mt-5 offset-lg-2">
            <ul class="menu-entries p-5">
              <li class="mb-3">
                <router-link exact :to="{ name: 'home' }"> Accueil</router-link>
              </li>
              <li class="mb-3">
                <router-link exact :to="{ name: 'collection-list', params: { type: 'own' } }"> Collections personnelles</router-link>
              </li>
              <li class="mb-3">
                <router-link exact :to="{ name: 'collection-list', params: { type: 'custom' } }"> Collections sur-mesure</router-link>
              </li>
              <li v-for="entry in entries.links" :key="entry.id" class="mb-3">
                <router-link v-if="entry.page" :to="{ name: 'textpage', params: { page_name: entry.page.slug } }">{{ entry.label || entry.page.title }}</router-link>
                <a v-else target="_new" :href="entry.url">{{ entry.label || entry.url }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="js">

import Vue from 'vue';

var data = {
    entries: [],
    menu_visible: false
}


export default {
    name: 'MainMenu',
    menu_visible: false,

    data: function() {
        return data
    },

    mounted: function () {
        let self = this
        Vue.axios.get("/main-menu")
           .then(function(response) {
               self.$data.entries  = response.data
           })
    },

    created: function() {
        this.$router.beforeEach((to, from, next) => {
            // hide menu
            document.documentElement.classList.remove('menu-open')
            this.menu_visible = false

            next()
        })
    },
    methods: {
        // Triggered by the menu button (open/close)
        handleToggleMenu: function() {
          // Put the html tag in the menu-open state (overflow hidden)
          document.documentElement.classList.toggle('menu-open')
          this.menu_visible = !this.menu_visible
        }
    }


}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.menu-entries > li {
  display: flex;

  &:hover {
    a::after {
      width: 100%;
      left: 0px;
    }
  }

  a {
    text-decoration: none;
    position: relative;

    &.router-link-active {
      font-weight: 800;
    }

    &::after {
      position: absolute;
      content: "";
      width: 0%;
      height: 2px;
      background-color: $dark_grey;
      left: 50%;
      bottom: 2px;
      transition: all 0.2s ease;
    }
  }
}

// Menu icon

.menu-button {
  &:hover span {
    background-color: $orange !important;
  }

  margin-left: 20px;
}

#main-menu-icon {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 80px 0 0 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#main-menu-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: $light_grey;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#main-menu-icon span:nth-child(1) {
  top: 0px;
}

#main-menu-icon span:nth-child(2) {
  top: 14px;
}

#main-menu-icon span:nth-child(3) {
  top: 28px;
}

#main-menu-icon.open span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#main-menu-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#main-menu-icon.open span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

// menu
.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: white;

  z-index: -5;
  &.open {
    overflow: auto;
  }

  .menu-entries {
    list-style: none;
    font-size: 2.5em;
    color: $dark_grey;
  }
}

@media screen and (max-width: 768px) {
  .menu {
    .menu-entries {
      font-size: 1.5em;
    }
  }
}
</style>
