<template lang="html">
    <b-container fluid no-gutters class="home">
        <b-row class="tagline-menu">

            <b-col offset-lg="1" lg="6" offset="1" cols="10" class="text-center text-lg-left">
                <h2 class="main">Design, création et production d'objets céramiques.</h2>
            </b-col>

            <b-col class="main-menu" cols="11" md="5" lg="2">
                <ul class="main-menu-list text-center text-md-left">
                    <li>
                        <router-link :to="{name: 'collection-list', params: {type: 'own'}}">Collections personnelles</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'collection-list', params: {type: 'custom'}}">Créations sur-mesure</router-link>
                    </li>

                    <li><router-link to="/p-qui-suis-je">Qui suis-je ?</router-link></li>
                    <li><router-link to="/p-l-atelier">L'atelier</router-link></li>


                </ul>
            </b-col>

            <b-col class="main-menu" cols="11" md="5" lg="2">
                <ul class="main-menu-list text-center text-md-left">
                    <li><router-link to="/p-les-formations">Cours</router-link></li>
                    <li><a href="https://seija-ceramics.myshopify.com/" target="_new">Boutique</a></li>
                    <li><a href="#section-contact">Contact</a></li>
                </ul>
            </b-col>

        </b-row>

        <b-row class="arrow" id="section-my-creations">
            <b-col cols="12" align="center">
                <b-img src="./../assets/images/fleche@2x.png" alt="fleche" height="23px"/>
            </b-col>
        </b-row>


        <b-row class="collection-list-container">
            <b-col cols="12" class="collections">
                <b-tabs align="center" fade>
                    <b-tab title="Collections personnelles" active>
                        <CollectionList type="own"/>
                    </b-tab>

                    <b-tab title="Collections sur-mesure">
                        <CollectionList type="custom"/>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>


        <div id="section-about-me" class="about-me section">
            <b-row align-v="center">

                <b-col lg="3" xs="10" class="">
                    <h2 class="d-none d-md-block big-overlay">SEI<br/>JA</h2>
                    <b-img class="under-overlay" src="./../assets/about-me.jpg" fluid/>

                </b-col>

                <b-col lg="3" class="d-none d-md-block align-self-end pb-5">
                    <b-row align-h="end">
                        <b-col cols="4">
                            Nous suivre
                        </b-col>

                        <b-col cols="8">
                            <ul class="social-list">
                                <li><a href="https://www.instagram.com/seija.ceramics/" target="_">Instagram</a></li>
                                <li><a href="https://www.facebook.com/seijaceramics/" target="_">Facebook</a></li>
                                <li><a href="https://www.pinterest.fr/seija4843/" target="_">Pinterest</a></li>
                            </ul>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col id="about-me" cols="12" lg="5">
                    <h2 class="text-center text-lg-left">Qui suis-je ?</h2>

                    <h3 class="text-center text-lg-left">Céramiste alchimiste, accro aux goûts et autres expériences sensibles</h3>

                    <p class="text-center w-100 text-lg-left">
                        La valeur que l'on donne à un objet peut concourir à apporter du
                        sens à des gestes triviaux de chaque jour. C'est ce credo et une
                        addiction terrible pour le goût, qui m'ont amenée à travailler
                        principalement sur des pièces dédiées aux arts de la table, à
                        chercher à magnifier ces petits moments rituels de nos quotidiens.
                    </p>

                    <p class="mt-5 w-100">
                        <router-link class="link" to="/p-qui-suis-je">
                            <button class="shadow bg-white">Plus d'informations</button>
                        </router-link>
                    </p>

                </b-col>

            </b-row>

        </div>


        <div id="section-my-workshop" class="my-workshop">
            <b-row>

                <b-col order-lg="1" order="2" sm="12" lg="6">
                    <h2 class="text-center text-lg-left">L'atelier</h2>

                    <p class="w-100 text-lg-left text-center">
                        Recherche d'émaux, tests, cuissons, observation, tournage,
                        moulage, modelage, séchage, finitions, émaillage... De la motte
                        de terre à la goutte d'émail, toutes nos créations sont
                        intégralement conçues et fabriquées dans notre atelier Lillois par
                        l’utilisation de techniques potières traditionnelle.
                    </p>
                    <p class="text-center text-lg-left w-100">
                        <ul class="list-unstyled">
                            <li><router-link class="link" to="/p-l-atelier">Mieux nous connaître</router-link></li>
                        </ul>
                    </p>
                </b-col>


                <b-col order-lg="2" order="1" cols="12" lg="6">
                    <h2 class="big-overlay">ate<br/>lier</h2>
                    <b-img class="under-overlay" src="./../assets/my-workshop.jpg" fluid/>
                </b-col>


            </b-row>

        </div>

        <div id="section-classes" class="classes">
            <b-row>
                <b-col cols="12" lg="12" class="text-center">
                    <img src="./../assets/images/home/classes-img-1.jpg" srcset="./../assets/images/home/classes-img-1@2x.jpg 2x, ./../assets/images/home/classes-img-1@3x.jpg 3x"/>
                    <h2 class="big-overlay">LES COURS</h2>
                    <img src=""/>
                </b-col>
            </b-row>

            <b-row>

                <b-col cols="12" lg="6">

                    <h2 class="text-center text-lg-left">Cours</h2>
                    <p class="text-center text-lg-left w-100">
                        Du stage de découverte à l'apprentissage au long cours, l'atelier
                        est également un lieu de formation. Pour découvrir la terre,
                        ressentir son toucher, exercer la maitrise de ses gestes et acquérir
                        l'aisance nécessaire pour laisser libre cours à ses élans créatifs !
                    </p>
                    <p class="text-center text-lg-left w-100">
                        <ul class="list-unstyled">
                            <li><router-link class="link" to="/p-les-formations">Plus d'infos sur les cours</router-link></li>
                        </ul>
                    </p>
                </b-col>

                <b-col lg="6" class="d-none d-md-block">
                    <img class="push-up" src="./../assets/images/home/classes-img-2.png"/>

                    <p class="address">
                        <a href="https://facebook.com/lepoulpelille" target="_new">Le Pouple</a><br/>
                        <a target="_new" href="https://www.google.fr/maps/place/Seija/@50.6207915,3.02731,2003m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c2d513b4124dd5:0x759a2b5e7829c242!8m2!3d50.6207881!4d3.0298849!16s%2Fg%2F11gy0ssg4l?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D">7 rue Henri Barbusse<br>
                            59000 Lille<br></a>
                        <br>
                    </p>
                </b-col>


            </b-row>
        </div>


        <!--
             <div class="shop">
             <b-row>
             <b-col>
             <h2>Shop !</h2>
             <h3>Retrouvez mes créations en ligne</h3>
             </b-col>
             </b-row>
             </div>



        -->

        <CustomCraftInsert/>
        <Footer/>

    </b-container>

</template>

<script lang="js">
import Vue from 'vue'
import KonamiCode from 'vue-konami-code'

// @ is an alias to /src
import CollectionList from '@/components/CollectionList.vue'
import Footer from '@/components/Footer.vue'
import CustomCraftInsert from '@/components/CustomCraftInsert.vue'

export default {
    // eslint-disable-next-line
    name: 'home',
    components: {
        CollectionList,
        // eslint-disable-next-line
        Footer,
        CustomCraftInsert
    },

    methods: {
        konami: function() {
            const h = this.$createElement
            // Using HTML string
            const titleVNode = h('div', { domProps: { innerHTML: "...Sam ?!..." } })
            // More complex structure
            const messageVNode = h('div', { class: ['foobar'] }, [
                h('b-img', {
                    props: {
                        src: 'https://media.giphy.com/media/AKZoD2SprDuMg/giphy.gif',
                        thumbnail: true,
                        center: true,
                        fluid: true
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                title: [titleVNode],
                buttonSize: 'sm',
                centered: true,
                size: 'md',
                okTitle: 'Je préfère Seija !',
            })
        }
    },

    mounted: function() {
        var _this = this
        Vue.use(KonamiCode, {callback: function () {
            _this.konami()
        }})
    }
}
</script>

<style lang="scss">

@import "@/assets/scss/_variables.scss";

button {
    border:none;
    background-color: white;
    color: $orange;
    padding-top: 1rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    padding-left: 3rem;

    font-weight: 600;
    font-size: 0.9em !important;
}


.home {
    color: #35434a;
}


.arrow {
    margin-bottom: 40px;
}


.tagline-menu {
  padding-top: 5%;
  background-color: #fff;
}

.main-menu-list {
    padding-top: 10%;

    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.2;
    letter-spacing: normal;
    text-align: left;
    color: $light_grey;

    a {
        color: $light_grey !important;
    }

    // List control
    list-style: none;
}

h2.main {
  font-size: 2.5em;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  color: $dark_grey;
}

.my-workshop {
  .big-overlay {
    left: 0%;
    right: none;
 }

  .under-overlay {
      left: 5%;
  }
}

ul.social-list {
    list-style: none;
    li {
        font-weight: bold;
    }


}


.on-demand {
    background-color: #f5f5f5;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    text-align: center;

    padding-top: 5vh;

    h3 {
        font-size: 1.5em;
        font-weight: 800;
        margin-top: -20px;
    }

    .title {
        text-align: right;

    }

    .text {
        color: #7a8f99;
        line-height: 1.39;
        font-size: 1.2em;
        text-align: left;
    }

    button {
        position: relative;
        top: 20px;
    }

    margin-bottom: 80px;

}



.about-me, .my-workshop, .classes {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 100px;

    .under-overlay {
        width: 55%;
        position: relative;
        // top: 10vh;
    }

    h2 {
        font-size: 2.5em;
        font-weight: 500;
        color: #35434a;
        padding-left: 0;
        margin-bottom: 2rem;
        margin-top: 4rem;

        &.big-overlay {
            margin-top: 0px;
            z-index: 10;
            position: absolute;
            top: 0;
            right: 0%;
            font-size: 7vw;
            line-height: 1.33;
            letter-spacing: 65.1px;
            color: #dedfe0;
            font-weight: bold;
            text-transform: uppercase;
        }

    }

    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #dedfe0;
        margin-bottom: 2rem;
    }

    p {
        width: 80%;
        line-height: 30px;
    }
}

.about-me {
    h2.big-overlay {
        left: 45%;
    }

    .under-overlay {
        width: 100% !important;
    }

    .follow-me {
        bottom: 0;
    }

}


.classes {

    .big-overlay {
        left: 2% !important;
        right: 2% !important;
        font-size: 6vw !important;
        color: #f6a48d !important;
        margin-top: 10vh !important;

    }

    img {
        filter: grayscale(100%);
        transition: 0.3s;

        &:hover {
            filter: grayscale(0%);
        }

        &.push-up {
            margin-top: -250px;
            margin-bottom: 20px;
        }


    }

}

.collections {
    background-color: #f5f5f5;

    color: $grey_blue;

    .tabs > div:first-of-type {
        background-color: white;
        border-top: 1px solid $very_light_grey;
        border-bottom: 1px solid $collection-grey;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    a {
        color: $grey_blue !important;
    }

    .nav-tabs {
        background-color: white;
        border-bottom: none;

        li {
            padding-right: 5vw;
            padding-left: 5vw;
        }

        li a {
            border: none;
            font-size: 14px;
            font-weight: 600;

            color: $grey_blue !important;
            &.active {
                color: #f6a48d !important;
                text-decoration: underline;
                text-underline-offset: 5px;
            }
            text-transform: uppercase;

        }
    }
    
}

.contact {
    padding-top: 30px;
    padding-bottom: 30px;

    border-top: 1px solid #f5f5f5;

    color: #939db0;

    line-height: 20px; /* same as height! */

    font-size: 17px;

    .contact-me {
        font-size: 18px;
        font-weight: 600;
    }


    a {
        text-decoration: none;
        color: #f6a48d;
        font-style: italic;
    }


}

a.link {
    font-size: 1.2rem;
    font-weight: 800 !important;
    color: $dark_grey !important;
}

</style>
