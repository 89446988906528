<template lang="html">
    <div id="section-on-demand" class="on-demand">
    <b-row align-v="center" align-h="center">
        <b-col cols="10" lg="4" class="title text-center text-lg-right">
            <h3>Une création sur mesure ?</h3>
        </b-col>
        <b-col cols="10" lg="4" class="text text-center text-lg-left">
            Restaurants, professionnels addicts du goût, nous réalisons vos commandes sur mesure.
        </b-col>
    </b-row>
    <button onclick="location.href='mailto:bonjour@seija.fr?subject=On prend un thé ?';" class="shadow bg-white">Contactez-nous</button>
    </div>
</template>

<script lang="js">

export default {
    name: 'CustomCraftInsert',

    mounted: function () {
    }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.on-demand h3 {
    color: $dark_grey !important;
}

</style>
